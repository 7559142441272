.topParticleCanvas{
    background-color: #0e1e25;
    position: relative;
    margin-top: -45px;
    z-index: 0;
}
.bottomParticleCanvas{
    background-color: #0e1e25;
    position: relative;
    margin-bottom: -5px;
    z-index: 0;
}

#navBar{
    width:100%;
    background-color: #0e1e25;
    padding-bottom: 0px;
}
#entirePage{
    padding: 0;
    margin: 0;
    color: 0e1e25;
    display: block;
}
.aboutTextBlurb{
    font-size: 20px;
    margin-top: 0px;
    padding-top: 0px;
}
#skillText{
    font-size: 18px;

    margin-top: 0px;
    padding-top: 0px;
}
#root{
    margin: 0;
    padding:0;
}

body{
    background-color: white;
    padding: 0;
    margin: 0;
    position: relative;
    height: 100%;
    overflow-y: scroll;
}
#particleDiv{
    background-color:  #0e1e25;
    margin:0;
    padding: 0;
}
#title{
    position: relative;
    margin-top: -30px;
    padding: 0;
    top: 140px;
    margin-left: 5%;
    z-index: 1;
    color: white;
}

#aboutMeSection{
    padding-top: 25px;
    width: 100%;
    max-height: 900px;
    min-height: 600px;
}

h1{
    font-size: 50px;
}
#portfolioDiv{
    margin-top: 10px;
    max-height: 2000px;
    min-height: 500px;
}
#contactMeDiv{
    background-color: white;
    width: 100%;
    max-height: 2000px;
    text-align: center;
    padding: 10px;
}
.padded-container{
    padding: 16px;
}
.card {
    -webkit-box-shadow: 0 5px 8px 0 rgba(0,0,0,0.2);
            box-shadow: 0 5px 8px 0 rgba(0,0,0,0.2);
    -webkit-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s;
}

.card:hover {
    -webkit-box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
            box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

footer{
    text-align: center;
    background-color: #0e1e25;
    font-size: 20px;
    padding: 10px 10px 20px 10px;
    color: white;
    margin: 0px;

}
#textDiv{
    display: -ms-flexbox;
    display: flex;
    text-align: left;
    margin-top: -30px;

}
#picSection{
    margin: auto;
    padding: auto;
}
a{
    color: black;
}
a:visited{
    color: black;
}
.thick-navy-border {
    border-color: #0e1e25;
    border-width: 7px;
    border-style: solid;
    border-radius: 50%;
  }
.center {
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.responsive {
    width: 100%;
    max-width: 265px;
    height: auto;
    
}
@-webkit-keyframes blinking{
    from{opacity: 1;}
    to{opacity: 0;}
}
@keyframes blinking{
    from{opacity: 1;}
    to{opacity: 0;}
}

.blink{
   -webkit-animation: blinking 2.5s infinite;
           animation: blinking 2.5s infinite;
}
#returnArrow{
    display: block;
    position: relative;
    left: 48.3%;
}
.fab fa-twitter{
    padding-left: 5px;
}
.alert-custom{
    background-color:#055b77;
    color:#fff;
  }

@media only screen and (max-width: 600px) {
    .blogText{
        padding-left: 15px;
    }
    #navcollapse{
        float:left;
    }
    #faceShot{
        margin-left: auto;
        margin-right: auto;
        padding-bottom: 0l
    }
    #aboutMe{
        margin-left: auto;
        margin-right: auto;
    }
    #particleDiv{
        margin-top: -70px;
    }
    #returnArrow{
        left: 46%;
    }
    
}

@media only screen and (min-width: 600px) {
    
}
h4{
    background-color: #0e1e25;
    color: white;
    text-align: center;
    padding: 5px;
}
#summary{
    background-color: #0e1e25;
    color: white;
    text-align: center;
    padding: 5px;
}